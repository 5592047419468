/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable dot-notation */
import React, { useMemo, useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { format, isBefore, isValid } from 'date-fns'
import { NEXT_URL } from '_config/environment'

import { Select, MenuItem, InputLabel, FormControl, Typography } from '@material-ui/core'
import { Alert, AlertTitle, Grid } from '@mui/material'
import { TimePicker } from '@material-ui/pickers'
import WarningIcon from '@mui/icons-material/Warning'
import { MoneyInput, Dialog } from '@refera/ui-web'
import { Controller, useFormContext } from 'react-hook-form'
import { Danger as DangerIcon } from '@refera/ui-icons'

import CheckIcon from '@material-ui/icons/Check'

import { parseNumber } from '_utils/helpers'
import { EMAIL_REGEX } from '_utils/constants'
import Button from '_components/button'
import Svg, { ICON } from '_components/svg'
import ControlledDatePicker from '_components/ControlledDatePicker'
import { getDayPeriods } from '_modules/day-period/actions'
import { dayPeriodsSelector } from '_modules/day-period/selector'
import { getHolidays } from '_modules/holiday/actions'
import { holidaysSelector } from '_modules/holiday/selector'
import { getBusinessTimes } from '_modules/businessTime/actions'
import { businessTimeSelector } from '_modules/businessTime/selector'
import useRolePermission from '_hooks/use-role-permission'

import { getAllStepStatusOptionsSelector } from '_/modules/service-orders/selectors'
import { getStepStatusOptions } from '_/modules/service-orders/actions'
import { DATE_SUGGESTION_OPTIONS } from '../utils/general'
import { InputField, InputFieldMask, MultilineField } from '../textfield'
import PictureInput from '../picture-input'

import useStyles from './styles'
import { ConfidentialDataWarning } from '_/components/ConfidentialDataWarning'
// import SelfLearningModal from '_components/self-learning/self-learning-modal'
// import SelfLearningTemplate from '_components/self-learning/self-learning-template'
import {
  agencySelector,
  requestTypeSelector,
  responsibleTeamSelector,
} from '_/modules/agency/selectors'
import { userSelector } from '_/modules/authentication/selectors'
import { getAgency, getOptionsFieldName } from '_/modules/agency/actions'
import {
  agencyCustomFieldsFromServiceFormSelector,
  formAgencyCustomFieldsSelector,
} from '_/modules/form/selectors'

export const CLIENT_TYPE = [
  { option: 'Proprietário', value: 'property_owner_client' },
  { option: 'Inquilino', value: 'lessee_client' },
  { option: 'Funcionário da imobiliária', value: 'agency_worker_client' },
]

export const MANAGED_BY = [
  { option: 'Refera', value: 'refera' },
  { option: 'Minha empresa', value: 'my_company' },
]

export const AlertError = ({ message }) => {
  const styles = useStyles()
  return (
    <Alert
      severity="warning"
      color="error"
      icon={<WarningIcon fontSize="large" color="error" />}
      sx={{
        borderBottom: '4px groove red',
        borderEndEndRadius: 0,
        borderEndStartRadius: 0,
      }}
    >
      <AlertTitle>
        <Typography variant="h7" component="h7" className={styles.alertTitle}>
          Atenção!
        </Typography>
      </AlertTitle>
      <Typography variant="body1" component="span" className={styles.reasonText}>
        {message}
      </Typography>
    </Alert>
  )
}

export const StartPage = ({ data, payload, menuProps, autoComplete, serviceOrderId }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { control, errors, register, setValue } = useFormContext()
  const agency = useSelector(agencySelector)
  const requestTypeOptions = useSelector(requestTypeSelector)
  const responsibleTeamOptions = useSelector(responsibleTeamSelector)
  const formAgencyCustomFieldsOptions = useSelector(agencyCustomFieldsFromServiceFormSelector)
  const user = useSelector(userSelector)?.toJS()
  const { isManager: isAgencyWorker } = useRolePermission()
  const stepStatusList = useSelector(getAllStepStatusOptionsSelector)

  // Custom Fields
  const responsibleTeam = agency?.customFields?.toJS().includes('responsible_team')
  const externalId = agency?.customFields?.toJS().includes('external_id')
  const approvedMaximumValue = agency?.customFields?.toJS().includes('approved_maximum_value')
  const requestType = agency?.customFields?.toJS().includes('request_type')

  useEffect(() => {
    if (user?.agency) {
      dispatch(getAgency(user?.agency))
      dispatch(getOptionsFieldName(user?.agency))
      dispatch(getStepStatusOptions())
    }
  }, [dispatch, user?.agency])

  return (
    <Grid className={styles.content}>
      <FormControl className={styles.formControl}>
        <InputLabel variant="standard" id="clientType">
          Tipo de cliente
        </InputLabel>
        <Controller
          control={control}
          name="clientType"
          defaultValue={autoComplete?.tipo || payload?.clientType || data?.clientType || ''}
          rules={{ required: 'Campo requerido.' }}
          as={
            <Select
              MenuProps={menuProps}
              fullWidth
              labelId="clientType"
              className={styles.selectInput}
              required
            >
              {CLIENT_TYPE?.sort((x, y) => (x.option < y.option ? -1 : 1))?.map(object => {
                if (isAgencyWorker && object.value !== 'agency_worker_client') {
                  return (
                    <MenuItem key={object.value} value={object.value} disabled>
                      {object.option}
                    </MenuItem>
                  )
                }
                return (
                  <MenuItem key={object.value} value={object.value}>
                    {object.option}
                  </MenuItem>
                )
              })}
            </Select>
          }
        />
      </FormControl>

      {data?.clientType === 'agency_worker_client' && agency?.pilotAutoTicket && (
        <>
          <FormControl className={styles.formControl}>
            <InputLabel variant="standard" id="managedBy">
              Gerenciado por
            </InputLabel>
            <Controller
              control={control}
              name="managedBy"
              rules={{ required: 'Campo requerido.' }}
              defaultValue={data?.managedBy || 'refera'}
              as={
                <Select
                  MenuProps={menuProps}
                  fullWidth
                  labelId="managedBy"
                  className={styles.selectInput}
                  required
                >
                  {MANAGED_BY.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.option}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          </FormControl>
        </>
      )}

      {data?.clientType === 'agency_worker_client' &&
        data?.managedBy === 'my_company' &&
        agency?.pilotAutoTicket && (
          <FormControl className={styles.formControl}>
            <InputLabel variant="standard" id="serviceOrderType">
              Tipo de chamado
            </InputLabel>
            <Controller
              control={control}
              name="serviceOrderType"
              rules={{ required: 'Campo requerido.' }}
              defaultValue={data?.serviceOrderType || ''}
              as={
                <Select
                  MenuProps={menuProps}
                  fullWidth
                  labelId="serviceOrderType"
                  className={styles.selectInput}
                  required
                >
                  {stepStatusList
                    .filter(step => step.intermediary)
                    .sort((a, b) => a.desc.localeCompare(b.desc))
                    .map(filteredStep => (
                      <MenuItem key={filteredStep.id} value={filteredStep.name}>
                        {filteredStep.desc}
                      </MenuItem>
                    ))}
                </Select>
              }
            />
          </FormControl>
        )}

      {!serviceOrderId && !!formAgencyCustomFieldsOptions?.howLongRentingProperty?.length && (
        <FormControl className={styles.formControl}>
          <InputLabel variant="standard" id="howLongRentingProperty">
            Há quanto tempo você está alugando o imóvel?
          </InputLabel>
          <Controller
            control={control}
            name="howLongRentingProperty"
            defaultValue=""
            rules={{ required: 'Campo requerido.' }}
            as={
              <Select
                MenuProps={menuProps}
                fullWidth
                labelId="howLongRentingProperty"
                className={styles.selectInput}
                required
              >
                {formAgencyCustomFieldsOptions.howLongRentingProperty
                  ?.sort((x, y) => (x.label < y.label ? -1 : 1))
                  ?.map(object => {
                    return (
                      <MenuItem key={object.value} value={object.value}>
                        {object.label}
                      </MenuItem>
                    )
                  })}
              </Select>
            }
          />
        </FormControl>
      )}

      {!serviceOrderId && !!formAgencyCustomFieldsOptions?.aboutTheProblem?.length && (
        <FormControl className={styles.formControl}>
          <InputLabel variant="standard" id="aboutTheProblem">
            Em relação ao seu problema, é correto afirmar que:
          </InputLabel>
          <Controller
            control={control}
            name="aboutTheProblem"
            defaultValue=""
            rules={{ required: 'Campo requerido.' }}
            as={
              <Select
                MenuProps={menuProps}
                fullWidth
                labelId="aboutTheProblem"
                className={styles.selectInput}
                required
              >
                {formAgencyCustomFieldsOptions.aboutTheProblem
                  ?.sort((x, y) => (x.label < y.label ? -1 : 1))
                  ?.map(object => {
                    return (
                      <MenuItem key={object.value} value={object.value}>
                        {object.label}
                      </MenuItem>
                    )
                  })}
              </Select>
            }
          />
        </FormControl>
      )}

      {!serviceOrderId && responsibleTeam && (
        <FormControl className={styles.formControl}>
          <InputLabel variant="standard" id="clientType">
            Equipe responsável
          </InputLabel>
          <Controller
            control={control}
            name="responsibleTeam"
            defaultValue=""
            rules={{ required: 'Campo requerido.' }}
            as={
              <Select
                MenuProps={menuProps}
                fullWidth
                labelId="responsibleTeam"
                className={styles.selectInput}
                required
              >
                {responsibleTeamOptions
                  ?.sort((x, y) => (x.option < y.option ? -1 : 1))
                  ?.map(object => {
                    return (
                      <MenuItem key={object.value} value={object.value}>
                        {object.option}
                      </MenuItem>
                    )
                  })}
              </Select>
            }
          />
        </FormControl>
      )}
      {!serviceOrderId && externalId && (
        <FormControl className={styles.formControl}>
          <InputField
            label="Código da intermediária"
            name="externalId"
            errors={errors}
            inputRef={register({ required: 'Campo requerido.', maxLength: 50 })}
            defaultValue=""
            inputProps={{
              maxLength: 50,
            }}
            className={styles.selectInput}
          />
        </FormControl>
      )}
      {!serviceOrderId && approvedMaximumValue && (
        <FormControl className={styles.formControl}>
          <MoneyInput
            value=""
            name="approvedMaximumValue"
            onChange={e => setValue('approvedMaximumValue', e.floatValue, { shouldDirty: true })}
            label="Valor máximo aprovado"
            decimalScale={2}
            style={{ fontSize: '16px' }}
            placeholder="R$ "
            ref={register('approvedMaximumValue')}
            className={styles.selectInput}
          />
        </FormControl>
      )}
      {!serviceOrderId && requestType && (
        <FormControl className={styles.formControl}>
          <InputLabel variant="standard" id="clientType">
            Tipo da solicitação
          </InputLabel>
          <Controller
            control={control}
            name="requestType"
            defaultValue=""
            rules={{ required: 'Campo requerido.' }}
            as={
              <Select
                MenuProps={menuProps}
                fullWidth
                labelId="requestType"
                className={styles.selectInput}
                required
              >
                {requestTypeOptions
                  ?.sort((x, y) => (x.option < y.option ? -1 : 1))
                  ?.map(object => {
                    return (
                      <MenuItem key={object.value} value={object.value}>
                        {object.option}
                      </MenuItem>
                    )
                  })}
              </Select>
            }
          />
        </FormControl>
      )}
    </Grid>
  )
}

export const ContactPage = ({ autoComplete, menuProps, data, payload }) => {
  const styles = useStyles()
  const { control, errors, register, setValue, getValues } = useFormContext()
  const formAgencyCustomFieldsOptions = useSelector(formAgencyCustomFieldsSelector)?.toJS()

  const agencyWorkerSelected = payload?.clientType === 'agency_worker_client'
  const { isManager: isAgencyWorker } = useRolePermission()

  const [addContactNumber, setAddContactNumber] = useState(0)
  const [showMessage, setShowMessage] = useState(false)
  const [messageText, setMessageText] = useState('')

  const inputMaskSwitch = useCallback(
    length => {
      if (length <= 14) {
        return '999.999.999-999'
      }

      return '99.999.999/9999-99'
    },
    [data?.contactCpfCnpj]
  )

  const validateCpfAndCNPJ = value => {
    if (parseNumber(value).length === 11 || parseNumber(value).length === 14) {
      return true
    }
    return 'Conteúdo inválido'
  }

  const handleBlur = () => {
    const inputValue = getValues('contactNumberConfirmation')
    if (inputValue !== data?.contactNumber) {
      setMessageText(
        'Os números de telefone não são iguais. Revise os dados do seu telefone para prosseguir. Vale lembrar que o telefone é de extrema importância, pois é o canal onde nosso time entrará em contato se necessário.'
      )
      setShowMessage(true)
    } else {
      setMessageText('')
      setShowMessage(false)
    }
  }

  const autoCompleteContactNumberDefault = useMemo(() => {
    if (autoComplete?.celularInquilino) {
      const contactNumber = autoComplete?.celularInquilino.replace(/'()'/g, '')

      if (contactNumber.length === 11) {
        return `+55${contactNumber}`
      }

      return contactNumber
    }

    return ''
  }, [autoComplete?.celularInquilino])

  useEffect(() => {
    if (payload) {
      if (payload.firstContactSecondary) {
        setAddContactNumber(1)
      }
      if (payload.secondContactSecondary) {
        setAddContactNumber(2)
      }
    }
  }, [payload.clientType])

  useEffect(() => {
    if (data.firstContactSecondary) {
      setAddContactNumber(1)
    }
    if (data.secondContactSecondary) {
      setAddContactNumber(2)
    }
  }, [data])

  const removeTelephone = useCallback(
    telephoneName => {
      if (telephoneName === 'contactNumber') {
        setValue('contactNumber', data?.firstContactSecondary)
        setValue('firstContactSecondary', data?.secondContactSecondary)
        setValue('secondContactSecondary', null)
        payload.contactNumber = data?.firstContactSecondary
        payload.firstContactSecondary = data.secondContactSecondary
          ? data.secondContactSecondary
          : null
        payload.secondContactSecondary = null
      }
      if (telephoneName === 'firstContactSecondary') {
        setValue('firstContactSecondary', data?.secondContactSecondary)
        setValue('secondContactSecondary', null)
        payload.firstContactSecondary = data.secondContactSecondary
          ? data.secondContactSecondary
          : null
        payload.secondContactSecondary = null
      }
      setAddContactNumber(addContactNumber - 1)
    },
    [
      payload.contactNumber,
      payload.firstContactSecondary,
      payload.secondContactSecondary,
      data.contactNumber,
      data.firstContactSecondary,
      data.secondContactSecondary,
    ]
  )

  const agencyWorkerContent = useMemo(() => {
    if (!agencyWorkerSelected) {
      return null
    }

    return (
      <>
        <InputField
          label="Código do imóvel"
          name="code"
          errors={errors}
          inputRef={register({ required: 'Campo requerido.', maxLength: 32 })}
          defaultValue={autoComplete?.codigo || payload?.code || ''}
          inputProps={{
            maxLength: 32,
          }}
          className={styles.selectInput}
        />
        {formAgencyCustomFieldsOptions?.includes('contract_code') && (
          <InputField
            label="Código do contrato"
            name="contractCode"
            errors={errors}
            inputRef={register()}
            defaultValue={autoComplete?.codigoContrato || payload?.contractCode || ''}
            inputProps={{
              maxLength: 32,
            }}
            className={styles.selectInput}
          />
        )}
        <FormControl className={styles.selectInput}>
          <InputLabel
            className={styles.selectInputLabel}
            variant="standard"
            id="isContractActive"
            error={!!errors.isContractActive}
          >
            O contrato está ativo?
          </InputLabel>
          <Controller
            control={control}
            name="isContractActive"
            defaultValue={autoComplete?.contratoAtivo || payload?.isContractActive || ''}
            rules={{
              validate: value => value !== '',
            }}
            as={
              <Select
                MenuProps={menuProps}
                fullWidth
                labelId="isContractActive"
                error={!!errors.isContractActive}
                className={styles.selectInput}
              >
                <MenuItem key={1} value>
                  Sim
                </MenuItem>
                <MenuItem key={2} value={false}>
                  Não
                </MenuItem>
              </Select>
            }
          />
        </FormControl>
        {typeof data?.isContractActive === 'boolean' && !data?.isContractActive && (
          <InputField
            label="Qual a localização da chave?"
            name="propertyKeysLocation"
            defaultValue={payload?.propertyKeysLocation || ''}
            errors={errors}
            inputRef={register({ required: 'Campo requerido.' })}
          />
        )}
      </>
    )
  }, [data, errors, payload])

  return (
    <Grid className={styles.content}>
      <Grid className={styles.alertInfo}>
        {showMessage && <AlertError message={messageText} />}
      </Grid>
      {agencyWorkerContent}
      {payload?.clientType || autoComplete?.tipo ? (
        <>
          <InputField
            label={isAgencyWorker ? 'Nome do cliente' : 'Nome'}
            name="contactName"
            defaultValue={autoComplete?.nomeInquilino || payload?.contactName || ''}
            errors={errors}
            inputRef={register({ required: !isAgencyWorker })}
          />
          <InputField
            label={isAgencyWorker ? 'Email do cliente' : 'Email'}
            name="contactEmail"
            defaultValue={autoComplete?.emailInquilino || payload?.contactEmail || ''}
            type="email"
            errors={errors}
            inputRef={register({
              required: !isAgencyWorker,
              pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
            })}
          />
          <InputFieldMask
            label={isAgencyWorker ? 'Telefone do cliente' : 'Telefone'}
            name="contactNumber"
            defaultValue={autoCompleteContactNumberDefault || payload?.contactNumber || ''}
            errors={errors}
            control={control}
            mask="+55 (99) 9 9999-9999"
            rules={{
              required: { value: !isAgencyWorker, message: 'Campo requerido' },
              minLength: { value: 19, message: 'Conteúdo inválido' },
            }}
            isToAddTelephone={addContactNumber !== 0 || payload.firstContactSecondary}
            removeTelephone={removeTelephone}
          />
          <InputFieldMask
            label="Confirme o telefone"
            name="contactNumberConfirmation"
            defaultValue=""
            errors={errors}
            control={control}
            onBlur={handleBlur}
            mask="+55 (99) 9 9999-9999"
            rules={{
              required: !isAgencyWorker,
              validate: value => value === data?.contactNumber,
            }}
          />
          {addContactNumber >= 1 && (
            <InputFieldMask
              label={isAgencyWorker ? 'Telefone do cliente' : 'Telefone'}
              name="firstContactSecondary"
              defaultValue={
                autoComplete?.celularInquilino?.replace(/'()'/g, '') ||
                payload?.firstContactSecondary ||
                ''
              }
              errors={errors}
              control={control}
              mask="+55 (99) 9 9999-9999"
              rules={{
                required: { value: !isAgencyWorker, message: 'Campo requerido' },
                minLength: { value: 19, message: 'Conteúdo inválido' },
              }}
              isToAddTelephone
              removeTelephone={removeTelephone}
            />
          )}
          {addContactNumber >= 2 && (
            <InputFieldMask
              label={isAgencyWorker ? 'Telefone do cliente' : 'Telefone'}
              name="secondContactSecondary"
              defaultValue={
                autoComplete?.celularInquilino.replace(/'()'/g, '') ||
                payload?.secondContactSecondary ||
                ''
              }
              errors={errors}
              control={control}
              mask="+55 (99) 9 9999-9999"
              rules={{
                required: { value: !isAgencyWorker, message: 'Campo requerido' },
                minLength: { value: 19, message: 'Conteúdo inválido' },
              }}
              isToAddTelephone
              removeTelephone={removeTelephone}
            />
          )}
          {addContactNumber < 2 && (
            <Button
              className={styles.iconBtn}
              onClick={() => {
                setAddContactNumber(addContactNumber + 1)
              }}
            >
              <Svg className={styles.icon} type={ICON.ADD_CIRCLE} />
              Adicionar outro número
            </Button>
          )}
          <InputFieldMask
            label={isAgencyWorker ? 'CPF/CNPJ do cliente' : 'CPF/CNPJ'}
            name="contactCpfCnpj"
            defaultValue={autoComplete?.cpfInquilino || payload?.contactCpfCnpj || ''}
            errors={errors}
            control={control}
            mask={inputMaskSwitch(data?.contactCpfCnpj?.length)}
            rules={{
              required: !isAgencyWorker,
              validate: { validateCpfAndCNPJ: value => validateCpfAndCNPJ(value) },
            }}
          />
        </>
      ) : (
        <></>
      )}
    </Grid>
  )
}

export const PropertyPage = ({ data, autoComplete, payload }) => {
  const styles = useStyles()
  const MAX_DATE = new Date('2262-04-11')
  const MIN_DATE = new Date('1980-02-01')
  const { control, errors, register, setValue } = useFormContext()
  const agencyWorkerSelected = payload?.clientType === 'agency_worker_client'
  const { isIntermediary: isAgencyWorker } = useRolePermission()

  useEffect(() => {
    if (autoComplete?.valorAluguel) {
      register('rentValue')
      setValue('rentValue', autoComplete?.valorAluguel)
    }

    if (autoComplete?.codigo) {
      register('code')
      setValue('code', autoComplete?.codigo)
    }
  }, [autoComplete?.valorAluguel, autoComplete?.codigo])

  return (
    <Grid className={styles.content}>
      <InputFieldMask
        label="O CEP do meu imóvel é"
        name="cep"
        defaultValue={payload?.cep || autoComplete?.cep || ''}
        errors={errors}
        control={control}
        mask="99999-999"
        rules={{
          required: 'Campo requerido.',
          validate: value => {
            if (value.length > 8) {
              return true
            }

            return 'Conteúdo inválido.'
          },
        }}
      />
      <InputField
        label="O endereço é"
        name="address"
        defaultValue={payload?.address || autoComplete?.endereco || ''}
        errors={errors}
        field={data?.address}
        inputRef={register({ required: 'Campo requerido.' })}
      />
      <InputFieldMask
        label="Número"
        name="number"
        defaultValue={payload?.number || autoComplete?.numero || ''}
        errors={errors}
        control={control}
        mask="999999"
        rules={{ required: 'Campo requerido.' }}
      />
      <InputField
        label="Complemento"
        name="complement"
        defaultValue={payload?.complement || autoComplete?.complemento || ''}
        errors={errors}
        inputRef={register({ required: false, maxLength: 50 })}
      />
      <InputField
        label="Bairro"
        name="neighborhood"
        defaultValue={payload?.neighborhood || autoComplete?.bairro || ''}
        errors={errors}
        field={data?.neighborhood}
        inputRef={register({ required: 'Campo requerido.' })}
      />
      <InputField
        label="Cidade"
        name="city"
        defaultValue={payload?.city || autoComplete?.cidade || ''}
        errors={errors}
        field={data?.city}
        inputRef={register({ required: 'Campo requerido.' })}
      />
      {isAgencyWorker && agencyWorkerSelected && (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid className={styles.datePickerContainer}>
                <ControlledDatePicker
                  required={false}
                  name="contractStartDate"
                  defaultValue={
                    autoComplete?.dataInicioContrato || payload?.contractStartDate || ''
                  }
                  label="Data de início do contrato"
                  genericErrorMessage="Data inválida"
                  minDate={MIN_DATE}
                  maxDate={MAX_DATE}
                />
              </Grid>
            </Grid>
          </Grid>
          <MoneyInput
            value={data.rentValue || autoComplete?.valorAluguel || payload?.rentValue || ''}
            name="rentValue"
            onChange={e => setValue('rentValue', e.floatValue, { shouldDirty: true })}
            label="Valor do aluguel"
            decimalScale={2}
            style={{ fontSize: '16px' }}
            placeholder="R$ "
            ref={register('rentValue')}
            className={styles.selectInput}
          />
        </>
      )}
    </Grid>
  )
}

export const AttachmentsPage = ({ data, payload }) => {
  const styles = useStyles()
  const { errors, register, setValue } = useFormContext()

  useEffect(() => {
    register('attachments')
    setValue('attachments', [])
  }, [])

  return (
    <Grid className={styles.content}>
      <MultilineField
        multiline
        charCount
        rows={2}
        data={data}
        label="Descrição"
        name="description"
        defaultValue={payload?.description || ''}
        errors={errors}
        inputRef={register({ required: 'Campo requerido.', maxLength: 3000 })}
      />
      <ConfidentialDataWarning style={{ marginTop: '-2.5rem' }} inServiceForm />
      <PictureInput
        name="attachments"
        value={data?.attachments}
        defaultValue={payload?.attachments || []}
        setValue={setValue}
        isDocument={false}
      />
    </Grid>
  )
}

export const FinalPage = ({ payload }) => {
  const styles = useStyles()
  const showMessage =
    payload?.clientType === 'lessee_client' || payload?.clientType === 'property_owner_client'

  const [open, setOpen] = useState(true)

  const onClick = useCallback(() => {
    if (showMessage) {
      // OPENS REQUESTER APP
      window.open(`${NEXT_URL}/login`)
    }
    setOpen(false)
  }, [open, showMessage])

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      direction="column"
      className={styles.container}
    >
      <Grid container alignItems="center" justify="center" className={styles.iconWrapper}>
        <CheckIcon className={styles.checkIcon} />
      </Grid>
      <Typography variant="h1" className={styles.title}>
        Chamado aberto com sucesso
      </Typography>
      <Typography variant="h3" component="p" className={styles.description}>
        Em breve entraremos em contato para dar andamento ao chamado
      </Typography>

      {showMessage && (
        <Dialog
          open={open}
          icon={DangerIcon}
          type="info"
          subject="Atenção!"
          description="Ao finalizar a criação do seu chamado de manutenção, você será direcionado para a área
              do solicitante, onde você poderá acompanhar todo o andamento e histórico dos seus
              chamados."
          onApprove={onClick}
        />
      )}
    </Grid>
  )
}

export const PropertyOwnerPage = ({ data, propertyData }) => {
  const styles = useStyles()
  const { control, errors, register } = useFormContext()

  const inputMaskSwitch = useCallback(
    length => {
      if (length <= 14) {
        return '999.999.999-999'
      }

      return '99.999.999/9999-99'
    },
    [data?.contactCpfCnpj]
  )

  const validateCpf = value => {
    if (parseNumber(value).length === 11 || parseNumber(value).length === 14) {
      return true
    }
    return 'Conteúdo inválido'
  }

  const isValidEmail = email => {
    if (EMAIL_REGEX.test(email)) {
      return true
    }
    return 'Conteúdo inválido'
  }

  const validateWhatsapp = number => {
    if (parseNumber(number).length > 8) {
      return true
    }
    return 'Conteúdo inválido'
  }

  return (
    <Grid className={styles.content}>
      <InputField
        label="Nome do proprietário do imóvel"
        name="propertyName"
        defaultValue={propertyData?.propertyName || ''}
        errors={errors}
        inputRef={register({ required: false })}
      />
      <InputFieldMask
        label="CPF/CNPJ do proprietário do imóvel"
        name="cpfOrCnpj"
        defaultValue={propertyData?.cpfOrCnpj || ''}
        errors={errors}
        control={control}
        mask={inputMaskSwitch(data?.cpfOrCnpj?.length)}
        rules={{
          validate: { validateCpf: value => validateCpf(value) },
        }}
      />
      <InputField
        label="Email do proprietário do imóvel"
        name="propertyEmail"
        defaultValue={propertyData?.propertyEmail || ''}
        type="email"
        errors={errors}
        inputRef={register({
          required: false,
          validate: { isValidEmail: value => isValidEmail(value) },
        })}
      />
      <InputFieldMask
        label="Whatsapp do proprietário do imóvel"
        name="propertyWhatsapp"
        defaultValue={propertyData?.propertyWhatsapp || ''}
        errors={errors}
        control={control}
        mask="+55 (99) 9 9999-9999"
        rules={{
          required: false,
          validate: { validateWhatsapp: value => validateWhatsapp(value) },
        }}
      />
    </Grid>
  )
}

export const DateSuggestions = ({ data, menuProps, payload }) => {
  const styles = useStyles()
  const MAX_DATE = new Date('2262-04-11')
  const dispatch = useDispatch()
  const dayPeriods = useSelector(dayPeriodsSelector)
  const holidaysList = useSelector(holidaysSelector)
  const businessTimesList = useSelector(businessTimeSelector)
  const { control, errors, setValue, setError } = useFormContext()
  const [showAlert, setShowAlert] = useState(false)
  const [alertText, setAlertText] = useState('')

  useEffect(() => {
    dispatch(getDayPeriods())
    dispatch(getHolidays())
    dispatch(getBusinessTimes())
  }, [dispatch])

  const isHoliday = useCallback(
    value => {
      const dateValue = new Date(value)
      const isAHoliday = holidaysList?.some(holiday => {
        const holidayDate = new Date(holiday.date)
        if (isValid(dateValue)) return format(dateValue, 'MM-dd') === format(holidayDate, 'MM-dd')
        return false
      })

      setHolidayError(isAHoliday)

      return isAHoliday ? `Você não pode escolher datas de feriados como sugestão.` : null
    },
    [holidaysList]
  )

  const [allDateIsTheSameError, setAllDateIsTheSameError] = useState(false)
  const [notWithinBusinessHoursError, setNotWithinBusinessHoursError] = useState(false)
  const [notAllowedIntervalError, setNotAllowedIntervalError] = useState(false)
  const [noAvailableTimeError, setNoAvailableTimeError] = useState(false)
  const [lessThanTwoHourError, setLessThanTwoHourError] = useState(false)
  const [invalidDateError, setInvalidDateError] = useState(false)
  const [holidayError, setHolidayError] = useState(false)
  const [dateInPastError, setDateInPastError] = useState(false)
  const [datesInTodayandTomorrowError, setDatesInTodayandTomorrowError] = useState(false)
  // const [showClickHere, setShowClickHere] = useState(false)
  // const [open, setOpen] = useState(false)

  // const onOpenSelfLearnigModal = useCallback(() => {
  //   setOpen(true)
  // }, [])

  function isInTodayAndTomorrow(dateList, chosenDate) {
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(today.getDate() + 1)
    today.setHours(0, 0, 0, 0)
    tomorrow.setHours(0, 0, 0, 0)

    const dates = [...dateList, new Date(chosenDate)]
    const countDatesBetweenTodayAndTomorrow = dates.filter(
      date => date >= today && date <= tomorrow
    ).length
    return countDatesBetweenTodayAndTomorrow === 3
  }

  function handleErrorsAndFlags(setFieldErrorCallback, nameDateField, message = null) {
    setFieldErrorCallback(!!message)
    nameDateField.forEach(field =>
      setError([field], {
        type: 'validate',
        message,
      })
    )
  }

  const validateDates = useCallback(
    (value, nameField) => {
      function isEqualRefera(firstDate, secondDate) {
        firstDate?.setHours(0, 0, 0, 0)
        secondDate?.setHours(0, 0, 0, 0)
        return firstDate?.getTime() === secondDate?.getTime()
      }

      const NOW = new Date()
      NOW.setHours(0, 0, 0, 0)

      const chosenDate = new Date(value)
      chosenDate.setHours(23, 59, 59, 999)
      const nameDateField = ['firstSuggestionDate', 'secondSuggestionDate', 'thirdSuggestionDate']
      const {
        firstSuggestionDate: firstDate,
        secondSuggestionDate: secondDate,
        thirdSuggestionDate: thirdDate,
      } = data

      const dateList = [
        nameField !== 'firstSuggestionDate' && firstDate,
        nameField !== 'secondSuggestionDate' && secondDate,
        nameField !== 'thirdSuggestionDate' && thirdDate,
      ].filter(field => field)

      const hasHoliday = isHoliday(value)

      const weekDay = moment(chosenDate).format('dddd').toLowerCase()
      const businessTime = businessTimesList.find(
        businessDay => businessDay.dayWeek === weekDay && businessDay.endTime
      )
      // Prevents the 3 dates are the same
      if (
        dateList.length >= 2 &&
        !dateList.some(date => !isEqualRefera(new Date(date), chosenDate))
      ) {
        const message = 'Você não pode escolher três dias de sugestão iguais.'
        handleErrorsAndFlags(setAllDateIsTheSameError, nameDateField, message)
        // setShowClickHere(true)
        return message
      }

      if (
        allDateIsTheSameError &&
        dateList.some(date => !isEqualRefera(new Date(date), chosenDate))
      ) {
        handleErrorsAndFlags(setAllDateIsTheSameError, nameDateField)
      }

      // Prevents choosing the three dates between today and tomorrow
      if (dateList.length >= 2 && isInTodayAndTomorrow(dateList, value)) {
        const message =
          'Você deve selecionar pelo menos uma data após 48h da abertura do chamado para facilitar a escolha de prestadores.'
        handleErrorsAndFlags(setDatesInTodayandTomorrowError, nameDateField, message)
        return message
      }

      if (datesInTodayandTomorrowError && !isInTodayAndTomorrow(dateList, value)) {
        handleErrorsAndFlags(setDatesInTodayandTomorrowError, nameDateField)
        return null
      }
      // Prevents choosing a date before now.
      if (chosenDate < NOW) {
        setDateInPastError(true)
        return 'Você não pode escolher um dia de sugestão no passado.'
      }
      setDateInPastError(false)
      // Prevents choosing a date that there is no business time.
      if (isValid(chosenDate) && !businessTime) {
        setNoAvailableTimeError(true)
        return `Não há horário atendimento disponível para este dia`
      }

      // Prevents a holiday is choosed
      if (hasHoliday) {
        return hasHoliday
      }
      // Prevents choosing a date beyond the db date limit
      if (isValid(chosenDate) && !isBefore(chosenDate, MAX_DATE)) {
        setNotAllowedIntervalError(true)
        return `Você não pode escolher uma data fora do intervalo permitido.`
      }
      setNotAllowedIntervalError(false)
      // Prevents a invalid date is choosed
      if (!isValid(chosenDate)) {
        setInvalidDateError(true)
        return `Data inválida`
      }
      setInvalidDateError(false)

      return null
    },
    [data, isHoliday, setShowAlert, businessTimesList]
  )

  const validateInitialTime = useCallback(
    (value, name) => {
      const time = `${format(new Date(value), 'HH:mm')}:00`
      const date = moment(data[name]).format('YYYY-MM-DD')
      const NOW = moment()
      const choosedTime = data[name] ? new Date(`${date}T${time}`) : new Date(value)
      // setShowClickHere(false)

      if (choosedTime < NOW) {
        setDateInPastError(true)
        return 'Você não pode escolher um horário de sugestão no passado.'
      }
      setDateInPastError(false)

      const weekDay = moment(choosedTime).format('dddd').toLowerCase()

      const businessTime = businessTimesList.find(
        businessDay => businessDay.dayWeek === weekDay && businessDay.endTime
      )

      const startWithinBusinessHours = moment(value).isBetween(
        moment(businessTime?.startTime, 'HH:mm:ss'),
        moment(businessTime?.endTime, 'HH:mm:ss')
      )

      if (!startWithinBusinessHours) {
        // setShowClickHere(true)
        setNotWithinBusinessHoursError(true)
        return `Você não pode escolher um horário fora do horário comercial. Em caso de dúvidas.`
      }
      setNotWithinBusinessHoursError(false)
      return null
    },
    [data]
  )

  const isNotValidInterval = useCallback(
    (value, name, startField) => {
      const startTime = data[startField]
      const choosedData = new Date(
        `${moment(data[name]).format('YYYY-MM-DD')}T${format(new Date(value), 'HH:mm')}:00`
      )

      // setShowClickHere(false)
      if (!startTime || !value) {
        return null
      }

      // Obtem o dia da semana para o horário de início selecionado
      const weekDay = moment(choosedData).format('dddd').toLowerCase()

      // Encontra o horário comercial para o dia selecionado
      const businessTime = businessTimesList.find(time => time.dayWeek === weekDay && time.endTime)

      const endWithinBusinessHours = moment(value).isBetween(
        moment(businessTime?.startTime, 'HH:mm:ss'),
        moment(businessTime?.endTime, 'HH:mm:ss')
      )

      if (!endWithinBusinessHours) {
        // setShowClickHere(true)
        setNotWithinBusinessHoursError(true)
        return `Você não pode escolher um horário fora do horário comercial. Em caso de dúvidas.`
      }

      setNotWithinBusinessHoursError(false)

      // Verifica se o intervalo é menor que 2 horas.
      const intervalInHours = moment(value).diff(moment(startTime), 'hours', true)
      if (intervalInHours < 2) {
        setLessThanTwoHourError(true)
        return 'Você não pode escolher um intervalo de horários menor que duas horas.'
      }

      setLessThanTwoHourError(false)

      return null
    },
    [data, businessTimesList]
  )

  useEffect(() => {
    const errorKeyList = Object.keys(errors)
    if (
      errorKeyList?.length &&
      (allDateIsTheSameError ||
        notWithinBusinessHoursError ||
        lessThanTwoHourError ||
        dateInPastError ||
        noAvailableTimeError ||
        notAllowedIntervalError ||
        invalidDateError ||
        holidayError ||
        datesInTodayandTomorrowError)
    ) {
      setShowAlert(true)
      setAlertText(errors[errorKeyList[0]].message)
    } else {
      setAlertText('')
      setShowAlert(false)
      // setShowClickHere(false)
    }
  }, [data, errors, allDateIsTheSameError, datesInTodayandTomorrowError])

  const handleOnChangeSelect = useCallback((value, dateObj) => {
    setValue(dateObj.period, value)
  }, [])

  const periodOptions = useMemo(() => {
    const dynamicOptions =
      dayPeriods?.map(period => {
        const startTime = parseInt(period.startTime.split(':')[0], 10)
        const endTime = parseInt(period.endTime.split(':')[0], 10)

        return {
          label: `${period.desc} (${startTime}h até às ${endTime}h)`,
          value: period.id,
        }
      }) || []

    return [
      ...dynamicOptions,
      {
        label: 'Horário personalizado',
        value: 'custom',
      },
    ]
  }, [dayPeriods])

  return (
    <Grid className={styles.content}>
      <Grid className={styles.alertInfo}>{showAlert && <AlertError message={alertText} />}</Grid>

      <Grid className={styles.contentSubTitle}>
        <Typography>
          Sugira três possíveis ocasiões para que o prestador vá até a sua residência.
        </Typography>
        <Typography>
          Você será notificado sobre qual das sugestões foi aceita quando seu chamado for pego pelo
          prestador.
        </Typography>
      </Grid>
      {DATE_SUGGESTION_OPTIONS.map(dateObj => {
        // TODO: Refatorar com componentização
        return (
          <FormControl className={styles.formContainer} key={dateObj.name}>
            <Grid>
              <Typography variant="h4" component="h2">
                {dateObj.label}
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid className={styles.datePickerContainer}>
                    <ControlledDatePicker
                      name={dateObj.name}
                      defaultValue={payload[dateObj.name] || null}
                      label="Dia"
                      genericErrorMessage="Data inválida"
                      rules={{
                        validate: value => validateDates(value, dateObj.name),
                      }}
                      maxDate={MAX_DATE}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name={dateObj.period}
                    rules={{
                      required: 'Campo obrigatório',
                    }}
                    defaultValue={payload[dateObj.period] || ''}
                    render={field => (
                      <Select
                        key={dateObj.period}
                        label="Período do dia"
                        MenuProps={menuProps}
                        fullWidth
                        className={styles.selectInput}
                        required
                        errors={errors}
                        onChange={(event, value) => handleOnChangeSelect(value, dateObj.name)}
                        errorMessage={errors?.[dateObj.period]?.message}
                        {...field}
                      >
                        {periodOptions?.map(({ label, value }) => (
                          <MenuItem key={value} value={value}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </Grid>

                {(data[dateObj.period] === 'custom' || payload[dateObj.period] === 'custom') && (
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          name={dateObj.startTime}
                          defaultValue={payload?.[dateObj.startTime] || null}
                          value={payload?.[dateObj.startTime]}
                          rules={{
                            required: 'Campo obrigatório',
                            validate: value => validateInitialTime(value, dateObj.name),
                          }}
                          as={
                            <TimePicker // TODO: Componentizar para reutilização futura
                              label={
                                <Typography className={styles.labelTime}>
                                  Horário inicial
                                </Typography>
                              }
                              variant="inline"
                              ampm={false}
                              format="HH:mm"
                              placeholder="HH:MM"
                              refuse={/[^\d\\.]+/gi}
                            />
                          }
                        />

                        {errors?.[dateObj.startTime]?.message && (
                          <Typography
                            variant="subtitle1"
                            component="span"
                            className={styles.errorMessage}
                          >
                            Horário inválido
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          name={dateObj.endTime}
                          defaultValue={payload?.[dateObj.endTime] || null}
                          rules={{
                            required: 'Campo obrigatório',
                            validate: value =>
                              isNotValidInterval(value, dateObj.name, dateObj.startTime),
                          }}
                          as={
                            <TimePicker
                              label={
                                <Typography className={styles.labelTime}>Horário final</Typography>
                              }
                              variant="inline"
                              ampm={false}
                              format="HH:mm"
                              placeholder="HH:MM"
                              refuse={/[^\d\\.]+/gi}
                            />
                          }
                        />

                        {errors?.[dateObj.endTime]?.message && (
                          <Typography
                            variant="subtitle1"
                            component="span"
                            className={styles.errorMessage}
                          >
                            Horário inválido
                          </Typography>
                        )}
                      </Grid>
                    </Grid>

                    <Typography className={styles.dateInfo}>
                      Deve haver um intervalo de pelo menos duas horas entre os horários sugeridos
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </FormControl>
        )
      })}
      {/* <SelfLearningModal
        modalOpen={false}
        title="Sugestões de horário"
        onCancel={() => setOpen(false)}
        template={<SelfLearningTemplate />}
      /> */}
    </Grid>
  )
}
